import { Injectable } from '@angular/core';
import { BackendService } from '@services/backend.service';
import { CurrencyEndpoints } from '@pages/general-maintenance/pages/currency/constant/currency.endpoints.constant';
import { ApiResponse } from 'app/core/types/api.type';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { map } from 'rxjs';
import { Currency } from '@pages/general-maintenance/pages/currency/types/currency.type';
import { ApiProps } from 'app/core/enums/api.enum';
import {
  CurrencyProps,
  CurrencyStatus,
  DistributionBodiesProps
} from '@pages/general-maintenance/pages/currency/enums/currency.enum';
import { DropdownService } from '@components/atoms/form-inputs/components/next-dropdown/services/dropdown.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  constructor(
    private _backendService: BackendService,
    private _dropdownService: DropdownService
  ) {}

  getCurrencies() {
    return this._backendService.get(CurrencyEndpoints.config.LIST).pipe(
      map((response: ApiResponse<Currency>) => {
        return NextObjectHelper.getPropertyFromObject(
          response,
          [ApiProps.RESULT, DistributionBodiesProps.CURRENCIES],
          []
        );
      })
    );
  }

  getDropdownList() {
    return this._dropdownService.getDropdownTranslationList(
      CurrencyEndpoints.config.LIST,
      { valueProp: CurrencyProps.CODE, labelProp: CurrencyProps.DESCRIPTION },
      { resultWithinProp: ['result', 'currencies'], params: { status: CurrencyStatus.ACTIVE } }
    );
  }
}
