export enum CurrencyProps {
    CODE = 'code',
    DESCRIPTION = 'description',
    STATUS = 'status',
    YEAR_DAYS = 'yearDays',
    DECIMAL_CONVERSION_FACTOR = 'decimalConversionFactor',
    DECIMAL_CONVERSION_ROUNDING = 'decimalConversionRounding'
}

export enum CurrencyStatus {
    ACTIVE = 'ACTIVE',
    SUSPENDED = 'SUSPENDED'
}

export enum DistributionBodiesProps {
    CURRENCIES = 'currencies'
}